import React, {
  Dispatch,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { FilePreview } from "../../FileActions/Preview";
import { FileShare } from "../../FileActions/Share";
import { FileDelete } from "../../FileActions/Delete";
import { TreeNodeType } from "../../TreeNode";
import {

  useRecoilState,
  useRecoilValue,

} from "recoil";
import { userInfo } from "../../../store/userInfo";

import { FileDownload } from "../../FileActions/Download";
import { FileRename } from "../../FileRename";
import usePermissions from "../../../hooks/usePermissions";

import axios from "axios";
import { BASE_URL } from "../../../api/constants";
import { isLoading } from "../../sumPdf";

interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  setRefresh: Dispatch<React.SetStateAction<boolean>>;
  setFile?: Dispatch<React.SetStateAction<string>>;
  setPopUpRename?: Dispatch<React.SetStateAction<boolean>>;
  setNameFile?: Dispatch<React.SetStateAction<string>>;
  node: TreeNodeType;
  fileType: string;
  setNodes?: Dispatch<React.SetStateAction<boolean>>;
  refreshParent?: Function;
  onClickFunc: (question: string, url: string) => void;
}
export const fileDownload = (email: String, filename: string) => {
  return axios
    .get(`${BASE_URL}/tree/downloadFile/${filename}`, {
      withCredentials: true,
    })
    .then((res) => {
      axios.post(
        `${BASE_URL}/fileLogs/sendFileLog`,
        { email, action: "Download", fileName: filename },
        {
          withCredentials: true,
        }
      );
      return res.data;
    })
    .catch((err) => {
      console.log(err);

      return false;
    });
};

export const FilePopup = ({
  setNameFile,
  setPopUpRename,
  showModal,
  setShowModal,
  setFile,
  setRefresh,
  node,
  refreshParent,

  onClickFunc,
}: IProps) => {
  const filename = node.displayName;
  const popup = useRef<HTMLDivElement>(null);
  const [shortName, setShortName] = useState("");
  const [text, setText] = useState(filename);
  const [question, setQuestion] = useState("");
  const [url, setUrl] = useState("");
  const { isContentManagerUser } = usePermissions();
  useEffect(() => {
    const maxChars = 25;
    let trimmedName = filename.split(".").slice(0, -1).join(".");
    if (trimmedName) {
      if (trimmedName.length > maxChars) {
        trimmedName = trimmedName.substring(0, maxChars - 1) + "..";
      }
      setShortName(trimmedName);
    }
  }, []);
  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;
  const handleClick = (e: any) => {
    if (popup.current?.contains(e.target)) {
      fileDownload(email, filename).then((result) => {
        setUrl(result);
      });
      return;
    }
    setShowModal(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const [isLoad, setIsLoading] = useRecoilState(isLoading);

  return (
    <>
      {showModal && (
        <BarStyle ref={popup}>
          <p>{text}</p>

          <AiInput
            placeholder="שאל שאלה על קובץ זה..."
            type="text"
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            value={question}
          ></AiInput>
          <CalcButton
            onClick={() => {
              onClickFunc(question, url);
              setIsLoading(true);
            }}
          >
            {!isLoad && "חשב תשובה"}
            {isLoad && "טוען תשובה..."}
          </CalcButton>

          <Buttons>
            <FilePreview
              setText={setText}
              setFile={setFile}
              setRefresh={setRefresh}
              filename={node.name}
              email={user.user.userPrincipalName}
              shortName={shortName}
            />

            <FileDownload
              setText={setText}
              filename={node.name}
              email={user.user.userPrincipalName}
              shortName={shortName}
            />

            {isContentManagerUser && (
              <FileDelete
                setfileName={setText}
                setText={setText}
                shortName={shortName}
                node={node}
                refreshParent={refreshParent}
                setShowModal={setShowModal}
              />
            )}

            <FileShare
              setText={setText}
              filename={node.name}
              email={user.user.userPrincipalName}
              shortName={shortName}
            />
            {isContentManagerUser && (
              <FileRename
                filename={node.displayName}
                setPopUpRename={setPopUpRename}
                setNameFile={setNameFile}
              />
            )}
          </Buttons>
        </BarStyle>
      )}
    </>
  );
};

const Buttons = styled.div`
  width: auto;
  display: flex;
  justify-content: space-around;
`;

const CalcButton = styled.div`
  background-color: #3195ed; 
  color: #ffffff; 
  text-align: center;
  height: 30px;
  width: 100px; 
  font-size: 16px;
  cursor: pointer; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  border-radius: 10px;
  position: relative;
  right: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease; 

  &:hover {
    background-color: #616161; 
  }

  &:active {
    background-color: #353535; 
    transform: scale(1.05); 
  }
`;

const AiInput = styled.input`
  /* width: 80vw; */
  outline: none;
  height: 4vh;
`;

const BarStyle = styled.div`
  width: 90vw;
  background: #161616;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 5rem;

  p {
    margin: 0.5rem auto;
    /* padding-right: 1rem; */
    color: white;
    font-size: small;
  }

  button {
    background: none;
    border: none;
    padding-right: 1rem;
  }

  button:last-of-type {
    padding-right: 0.7rem;
  }

  img {
    padding: 0.5rem 0 0.5rem 0;
  }

  .star {
    width: 25px;
    height: 15px;
  }

  .eye {
    width: 25px;
    height: 15px;
  }

  .share {
    width: 25px;
    height: 15px;
  }
`;
