import styled from "styled-components";

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(127, 127, 127, 0.6);
`;
export const FieldName = styled.p`
  text-align: center;
  color: red;
  height: 5vh;
  font-size: 1rem;
`;
export const Wrapper = styled.div`
  width: max-content;
`;
export const X = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1vh;
  color: black;
`;
export const Answer = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1vh;
  color: black;
`;
export const ModalBody = styled.div`
  position: fixed;
  margin: 10% auto;
  padding: 2vh 2rem;
  width: 70vw;
  border-radius: 1rem;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  background-color: white;

 @media (max-height: 826px) {
    margin: 5% auto;
    width: 80vw;
    padding: 1rem;
  }

   @media (max-height: 770px) {
    margin: 5% auto;
    width: 80vw;
    padding: 1rem;
    height: auto;
  }
`;

export const TextDiv = styled.div`
  width: 70vw;
  font-size: 1rem;

   @media (max-height: 770px) {
   font-size: 0.9rem;
  }
`;
export const BoldWhatsapp = styled.b`
  margin-top: 2vh;
  color: black;
  font-size: 1rem;
`;
