import React, { useEffect, useState, useRef } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import styled from "styled-components";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useFile } from "../../hooks/useFile";
import { BASE_URL } from "../../api/constants";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import axios from "axios";
import { IconContext } from "react-icons";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
interface IProps {
  file: string;
  setFile?: React.Dispatch<React.SetStateAction<string>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FileView = ({
  file,
  setFile,
  setShowModal,
  setRefresh,
}: IProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isPageSelectorOpen, setIsPageSelectorOpen] = useState(false);
  const [pageInputValue, setPageInputValue] = useState("");
  const pageSelectorRef = useRef<HTMLDivElement>(null);
  const { setTreeFile } = useFile();
  const [favorite, setFavorite] = useState(false);
  const [showFavorite, setShowFavorite] = useState(false);
  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;
  const { treeFile } = useFile();

  useEffect(() => {
    axios
      .post(
        `${BASE_URL}/likes/isFavorite`,
        { email: email, fileName: treeFile.filename },
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result.data) {
          setFavorite(result.data.likeStatus);
          setShowFavorite(true);
        }
      });
  }, []);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const handlePageChange = (newPage: number) => {
    if (numPages !== null && newPage >= 1 && newPage <= numPages) {
      setPageNumber(newPage);
    }
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    if (!/^\d*$/.test(value)) {
      return;
    }
  
    const newValue = value.padStart(pageInputValue.length, '0').slice(-pageInputValue.length);
    setPageInputValue(newValue);
    
    const inputPage = parseInt(value, 10);
    if (!isNaN(inputPage) && numPages !== null) {
      if (inputPage >= 1 && inputPage <= numPages) {
        setPageNumber(inputPage);
      }
    }
  };

  const handlePageInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const newValue = '0' + pageInputValue.slice(0, -1);
      setPageInputValue(newValue.replace(/^0+/, ''));
    }
  };

  const handlePageInputBlur = () => {
    if (pageInputValue === "" || parseInt(pageInputValue, 10) < 1) {
      setPageInputValue("1");
      setPageNumber(1);
    } else if (numPages !== null && parseInt(pageInputValue, 10) > numPages) {
      setPageInputValue(numPages.toString());
      setPageNumber(numPages);
    } else {
      setPageNumber(parseInt(pageInputValue, 10));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pageSelectorRef.current &&
        !pageSelectorRef.current.contains(event.target as Node)
      ) {
        setIsPageSelectorOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setPageInputValue(pageNumber.toString());
  }, [pageNumber]);

  return (
    <Wrapper style={{ height: "100%", width: "96vw" }}>
      <DivFavoriteandReturn>
        <FileViewButton
          onClick={() => {
            setFile && setFile("");
            setTreeFile({
              fileSrc: undefined,
              fileType: undefined,
              filename: undefined,
            });
            setShowModal?.(false);
            setRefresh(true);
          }}
        >
          חזור
        </FileViewButton>
        <IconeHer
          onClick={() => {
            setFavorite((prev) => !prev);
            axios
              .post(
                `${BASE_URL}/likes/toggleFavorite`,
                { email: email, fileName: treeFile.filename },
                {
                  withCredentials: true,
                }
              )
              .then((result) => {
                setRefresh(true);
                if (result.data) {
                }
              });
          }}
        >
          {showFavorite ? (
            favorite ? (
              <IconContext.Provider
                value={{ color: "#ED8A19", size: "2.5rem" }}
              >
                <div>
                  <AiFillStar />
                </div>
              </IconContext.Provider>
            ) : (
              <IconContext.Provider value={{ size: "2.5rem" }}>
                <div>
                  <AiOutlineStar />
                </div>
              </IconContext.Provider>
            )
          ) : (
            <></>
          )}
        </IconeHer>
      </DivFavoriteandReturn>
      <StyleDocument
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        options={{ height: 300 }}
      >
        <Page height={800} width={350} pageNumber={pageNumber} />
      </StyleDocument>

      {numPages && (
        <PaginationWrapper>
          <PaginationButton
            disabled={pageNumber === 1}
            onClick={() => handlePageChange(pageNumber - 1)}
          >
            <MdKeyboardArrowRight />
          </PaginationButton>

          <PageSelectorContainer ref={pageSelectorRef}>
      <CurrentPage
        onClick={() => setIsPageSelectorOpen(!isPageSelectorOpen)}
      >
        {pageNumber}/{numPages}
      </CurrentPage>
      {isPageSelectorOpen && (
        <PageSelectorDropdown>
          <PageInput
            type="text"
            value={pageInputValue}
            onChange={handlePageInputChange}
            onKeyDown={handlePageInputKeyDown}
            onBlur={handlePageInputBlur}
            min="1"
            max={numPages?.toString()}
          />
          <PageList>
            {Array.from({ length: numPages }, (_, i) => i + 1).map(
              (page) => (
                <PageItem
                  key={page}
                  onClick={() => {
                    handlePageChange(page);
                    setIsPageSelectorOpen(false);
                  }}
                >
                  {page}
                </PageItem>
              )
            )}
          </PageList>
        </PageSelectorDropdown>
      )}
    </PageSelectorContainer>

          <PaginationButton
            disabled={pageNumber === numPages}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            <MdKeyboardArrowLeft />
          </PaginationButton>
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};

const DivFavoriteandReturn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 94vw; 
  padding: 0 1rem; 
  margin-top: 1rem; 
  margin-bottom: 1rem; 
`;

const IconeHer = styled.div`
  height: 3rem;
  width: 2rem;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
`;

const FileViewButton = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 2rem;
  max-width: 30vw;
  background-color: #3195ed;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 0.5rem;
  font-size: large;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100vw;
  justify-content: center;
`;

const StyleDocument = styled(Document)`
  display: flex;
  justify-content: center;

  .react-pdf__Page {
    display: flex;
    position: relative;
    max-width: 100vw;
    /* max-height: 100vh; */
    /* width: 100%; */
    /* height: 100%; */
    overflow: hidden;
    /* overflow: auto; */
  }
`;

const PagePos = styled.p`
  display: flex;
  justify-content: center;
`;

const PaginationButton = styled.button`
  background: unset;
  display: flex;
  align-items: center;
  border: unset;
  border-radius: 100%;
  background: #8080804a;
  padding: 0.5rem;
  color: white;
  font-size: 20px;
  font-weight: 700;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  left: 0;
  right: 0;
`;

const PageSelectorContainer = styled.div`
  position: relative;
  margin: 0 1rem;
`;

const CurrentPage = styled.div`
  background-color: #3195ed;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
`;

const PageSelectorDropdown = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const PageInput = styled.input`
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 0rem;
  border: none;
  border-bottom: 1px solid #ccc;
  text-align: center;
  font-size: 1rem;
`;

const PageList = styled.ul`
  width: 100%;
  max-width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-x: hidden; 

`;

const PageItem = styled.li`
  width: 100%;
  text-align: center; 
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;