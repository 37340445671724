import { useState } from "react";
import styled from "styled-components";
import { BASE_URL } from "../../../api/constants";
import axios from "axios";
import { Dispatch } from "react";
import { IUpdate } from ".";
import { Button } from "../../Button";
import { DateInput } from "../../DateInput";
import { Input } from "../Input";
import { RichTextEditor } from "../../RichTextEditor";
interface IProps {
  setViewUpdate: Dispatch<React.SetStateAction<boolean>>;
  update: IUpdate;
}
export const ViewUpdate = ({ setViewUpdate, update }: IProps) => {
  const [name, setName] = useState(update?.name ? update.name : "");

  const [content, setContent] = useState(update.content);
  const [category, setCategory] = useState(update.category);
  const [startDate, setStartDate] = useState(
    new Date(update.startDate).toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date(update.endDate).toISOString().slice(0, 10)
  );
  const [output, setOutput] = useState("");

  const newUpdateVersion = {
    ...update,
    content,
    category,
    startDate,
    endDate,
    name,
  };

  return (
    <Wrapper>
      <FormWrapper>
        <h3>עריכת התרעה</h3>

        <p>תוכן ההתרעה</p>
        <RichTextEditor markup={content} setMarkUp={setContent} />
        <br />

        <Input
          placeholder=""
          value={name}
          setValue={setName}
          topText="כותרת ההתרעה"
          isTextArea={false}
        ></Input>

        <Input
          placeholder=""
          value={category}
          setValue={setCategory}
          topText="קטגוריה"
          isTextArea={false}
        ></Input>
        <Dates>
          <DateInput
            text={"תאריך התחלה"}
            value={startDate}
            setValue={setStartDate}
          />

          <DateInput
            text={"תאריך סיום"}
            value={endDate}
            setValue={setEndDate}
          />
        </Dates>
        <p>{output}</p>
        <Buttons>
          <Button
            onClick={() => {
              editUpdate(newUpdateVersion, setOutput);
            }}
          >
            עדכן
          </Button>

          <Button
            onClick={() => {
              deleteUpdate(newUpdateVersion, setOutput, setViewUpdate);
            }}
          >
            מחק
          </Button>
        </Buttons>
      </FormWrapper>
    </Wrapper>
  );
};
const editUpdate = (
  update: IUpdate,
  setOutput: Dispatch<React.SetStateAction<string>>
) => {
  return axios
    .post(
      `${BASE_URL}/adminUpdates/editUpdate`,
      {
        ...update,
      },
      { withCredentials: true }
    )
    .then((res) => {
      if (res?.data?.success) {
        setOutput("ההתרעה נשמרה בהצלחה");
        return true;
      }
      setOutput("לא היה ניתן להוסיף את ההתרעה, נסה שוב מאוחר יותר.");
      return false;
    });
};

const deleteUpdate = (
  update: IUpdate,
  setOutput: Dispatch<React.SetStateAction<string>>,
  setViewUpdate: Dispatch<React.SetStateAction<boolean>>
) => {
  return axios
    .post(
      `${BASE_URL}/adminUpdates/deleteUpdate`,
      {
        ...update,
      },
      { withCredentials: true }
    )
    .then((res) => {
      if (res?.data?.success) {
        setOutput("ההתרעה נמחקה בהצלחה");
        setViewUpdate(false);
        return true;
      }
      setOutput("לא היה ניתן למחוק את ההתרעה, נסה שוב מאוחר יותר.");
      return false;
    });
};

const Wrapper = styled.div`
  max-height: 80vh;
  width: 90vw;
  overflow: auto;
`;

const Buttons = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const Dates = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: right;
  align-items: right;
`;
