import React, { useEffect, useState } from "react";
import {
  DeleteButton,
  InjuriesNumberSelect,
  InjuriesSelect,
  InjuryDiv,
  InjuryLabel,
  InjuryWrapper,
} from "./styles";

export interface InjuryOption {
  injuryLevel: string | undefined;
  factorType: string | undefined;
  numOfInjuries: number;
}

export const Injury = ({
  setInjuries,
  injuryLevelInput,
  factorTypeInput,
  numOfInjuriesInput,
  placeholder,
  isDisabled,
  selectedUnitActivity, 
}: {
  setInjuries: React.Dispatch<React.SetStateAction<InjuryOption[]>>;
  injuryLevelInput: string | undefined;
  factorTypeInput: string | undefined;
  numOfInjuriesInput: number | undefined | string;
  placeholder: string | undefined;
  isDisabled: boolean;
  selectedUnitActivity: string;
}) => {
  const [injuryLevel, setInjuryLevel] = useState<string>(injuryLevelInput || "");
  const [factorType, setFactorType] = useState<string>(factorTypeInput || "");
  const [numOfInjuries, setNumberOfInjuries] = useState<number | string>(
    numOfInjuriesInput ? Number(numOfInjuriesInput) : ""
  );

  useEffect(() => {
    if (
      injuryLevel &&
      factorType &&
      numOfInjuries !== "הכנס כמות נפגעים" &&
      !isDisabled &&
      injuryLevel !== "בחר/י" &&
      factorType !== "בחר/י"
    ) {
      setInjuries((prev) => {
        prev = prev.filter(
          (inj) =>
            !(inj.injuryLevel === injuryLevel && inj.factorType === factorType)
        );
        return [
          ...prev,
          { factorType, injuryLevel, numOfInjuries: Number(numOfInjuries) },
        ];
      });
      setInjuryLevel("בחר/י");
      setFactorType("בחר/י");
      setNumberOfInjuries(0);
    }
  }, [injuryLevel, numOfInjuries, factorType, isDisabled, setInjuries]);
  const injuriesListToUse =
    selectedUnitActivity === "מלחמה/מבצע צבאי נרחב"
      ? subInjuriesLevelArr
      : injuriesLevelArr;

  return (
    <InjuryWrapper>
      <InjuryDiv>
        <InjuryLabel>גורם מעורב</InjuryLabel>
        <InjuriesSelect
          value={factorType}
          onChange={(e) => {
            setFactorType(e.target.value);
          }}
        >
          {factorTypeArr.map((factorType) => {
            return <option value={factorType}>{factorType}</option>;
          })}
        </InjuriesSelect>
      </InjuryDiv>

      <InjuryDiv>
        <InjuryLabel>כמות נפגעים</InjuryLabel>
        <InjuriesNumberSelect
           disabled={!factorType}
          value={numOfInjuries || ""}
          type="number"
          placeholder="כמות"
          onChange={(e) => {
            const value = Number(e.target.value);
            setNumberOfInjuries(value > 0 ? value : "");
          }}
        />
      </InjuryDiv>

      <InjuryDiv>
        <InjuryLabel>חומרת הפגיעה</InjuryLabel>
        <InjuriesSelect
          disabled={!numOfInjuries }
          value={injuryLevel}
          onChange={(e) => {
            setInjuryLevel(e.target.value);
          }}
        >
          {injuriesListToUse.map((injuriesLevel) => {
            return <option value={injuriesLevel}>{injuriesLevel}</option>;
          })}
        </InjuriesSelect>
      </InjuryDiv>

      {isDisabled && injuryLevel !== "בחר/י" && factorType && numOfInjuries && (
    <DeleteButton
      onClick={() => {
        setInjuries((prev) =>
          prev.filter(
            (inj) =>
              !(
                inj.injuryLevel === injuryLevel &&
                inj.factorType === factorType &&
                inj.numOfInjuries === Number(numOfInjuries)
              )
          ));
      }}
        >
          -
        </DeleteButton>
      )}
    </InjuryWrapper>
  );
};
export const injuriesLevelArr = [
  "בחר/י",
  "ללא פגיעה",
  "פצוע קל (ללא אשפוז)",
  "פצוע קל (שאושפז)",
  "פצוע בינוני",
  "פצוע קשה/אנוש",
  "חלל",
];

export const subInjuriesLevelArr = [
  "בחר/י",
  "ללא פינוי",
  "פינוי לא דחוף",
  "פינוי דחוף",
  "חלל",
];

const factorTypeArr = [
  "בחר/י",
  "סדיר",
  "מילואים",
  'אע"צ',
  "אזרח",
  "לא ידוע",
];
