import styled from "styled-components";

export const DeleteButton = styled.div`
  width: 8vw;
  height: 5vh;
  margin-bottom: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  color: red;
  position: absolute; 
  left: 0;
  top: 50%; 
  transform: translateY(-50%);
`;

export const InjuriesNumberSelect = styled.input`
  width: 15vw;
  height: 5vh;
  appearance: none;
  outline: none;
 padding-right: 5px;
  color: white;
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
  /* display: block; */
  color: #333;
  border: 1px solid #a39e9e;
  border-radius: 0px;
  background-color: #e4e4e4;
  /* margin-bottom: 0.5rem; */
  -ms-word-break: normal;
  word-break: normal;
  display: inline-block;
  font: inherit;
  box-sizing: border-box;
`;
export const InjuriesSelect = styled.select`
  width: 32vw;
  height: 5vh;
  appearance: none;
  outline: none;
  padding-right: 5px;
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  border: 1px solid #a39e9e;
  -ms-word-break: normal;
  word-break: normal;
  display: inline-block;
  font: inherit;
  box-sizing: border-box;
  background-image: linear-gradient(45deg, transparent 50%, #545252 50%),
    linear-gradient(135deg, #545252 50%, transparent 50%);
  background-position: calc(20% - 20px) calc(0.9em + 2px),
    calc(20% - 16px) calc(0.9em + 2px), 10% 0;
  background-size: 5px 5px, 5px 7px, 5em 3em;
  background-repeat: no-repeat;
  :focus {
    background-image: linear-gradient(45deg, #545252 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #545252 50%);
    background-position: calc(20% - 16px) 0.9em, calc(20% - 20px) 0.9em, 0% 0;
    background-size: 4px 6px, 5px 6px, 2.5em 4em;
    background-repeat: no-repeat;
  }
`;

export const InjuryWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  gap: 0.5rem;
  padding: 0 1rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  position: relative;
  align-items: center;
`;

export const InjuryDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2rem;
  width: 100%; 
  box-sizing: border-box; 
`;

export const InjuryLabel = styled.label`
  font-weight: bold;
  text-align: center;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  margin-bottom: 0.2rem;
  display: block;
  width: 100%;
  font-size: 0.9rem; 
  `;
