import React, { useState } from "react";
import styled from "styled-components";
import { UpdateRow } from "./UpdateRow";
import { TreeNodeType } from "../../TreeNode";
import { useSetRecoilState } from "recoil";
import { FilesWrapper } from "../../NewFiles/styles";
import { useFile } from "../../../hooks/useFile";
import { RenamePopup } from "../../RenamePopup";
import { FilePopup } from "../../File/FilePopup";
import { SumPdf, aiState, isLoading, shouldShowState } from "../../sumPdf";
import { VideoView } from "../../VideoView";
import { FileView } from "../../FileView";
import { AiModal } from "../../File/FilePopup/AiModal";
import { useRecoilValue } from "recoil";

interface IProps {
  favorites: TreeNodeType[];
  isFavoritesLoading: boolean;
  showModal: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshParent?: Function;
}

export const Center: React.FC<IProps> = ({
  favorites,
  isFavoritesLoading,
  setRefresh,
  setShowModal,
  showModal,
}) => {

  const aiAnswer = useRecoilValue(aiState);
  const shouldShow = useRecoilValue(shouldShowState);

  const [node, setNode] = useState<TreeNodeType>();
 
  const { treeFile } = useFile();
  const [popUpRename, setPopUpRename] = useState<boolean>(false);
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const [afterLoadingPopUP, setAfterLoadingPopUp] = useState(false);
  const [nameFile, setNameFile] = useState<string>("");

  const setAiAnswer = useSetRecoilState(aiState);
  const setShouldShow = useSetRecoilState(shouldShowState);
  const setIsLoading = useSetRecoilState(isLoading);
  const [popUpBol, setPopUpBol] = useState(false);

  return (
          <Wrapper>
            <AiModal shouldShow={shouldShow}>{aiAnswer}</AiModal>
      <FilesWrapper file={treeFile.fileSrc}>
        {isFavoritesLoading && (
          <Row>
            <p>טוען..</p>
          </Row>
        )}
        {!isFavoritesLoading &&
          favorites?.map((favorite) => {
            return (
              <UpdateRow
                key={favorite.displayName}
                node={favorite}
                setNode={setNode}
                setShowModal={setShowModal}
                setRefresh={setRefresh}
              />
            );
          })}
        {favorites.length === 0 && !isFavoritesLoading ? (
          <Row>
            <p>לא נמצאו מועדפים</p>
          </Row>
        ) : (
          ""
        )}
        {popUpRename && (
          <RenamePopup
            afterLoadingPopUP={afterLoadingPopUP}
            nameFile={nameFile} //שם הקובץ
            setNameFile={setNameFile}
            loadingPopUp={loadingPopUp} //הסתרה של הכן/לא
            setLoadingPopUp={setLoadingPopUp}
            setAfterLoadingPopUp={setAfterLoadingPopUp} //אחרי שהוא לחץ כן
            node={node}
            setRefresh={setRefresh} //מרנדר את התצוגה מחדש אחרי השינוי
            setPopUpRename={setPopUpRename} //הצגת הפופ אפ
          />
        )}

        {showModal && node && (
          <FilePopup
            refreshParent={setRefresh}
            setNameFile={setNameFile} //בשביל הפופאפ של השינוי שם
            setPopUpRename={setPopUpRename} //כדי להצגיד את הפופאפ של השינוי שם
            fileType={""} //זה סוג הקובץ (סרטון תמונה)
            showModal={showModal}
            setShowModal={setShowModal}
            setRefresh={setPopUpBol} //לא צריך
            node={node} //זה בעצם כל הקוביה של הקובץ של המעודפים
            onClickFunc={(question, url) => {
              if (url != "") {
                SumPdf(question, url).then((value) => {
                  setAiAnswer(value);
                  setShouldShow(true);
                  setIsLoading(false);
                });
              } else console.log("no url");
            }}
          />
        )}
      </FilesWrapper>
      {treeFile.fileSrc && (
        <FileViewWrapper>
          {node?.name.split(".").slice(-1)[0]?.toLowerCase() == "mp4" ? (
            <VideoView type={"video/mp4"} source={treeFile.fileSrc} />
          ) : (
            <FileView setRefresh={setRefresh} file={treeFile.fileSrc} /> //src
          )}
        </FileViewWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-height: 17vh;
  min-height: 0;
  border: none;
`;

const Row = styled.div`
  width: 100%;
  height: 4vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #cdeaff;
  /* border-top: 1px solid rgba(80, 101, 132, 0.63); */
  border-bottom: 1px solid rgba(80, 101, 132, 0.63);
  padding: 0.3rem 0;
  p {
    margin-right: 1.5rem;
    font-weight: bold;
  }
  /* @media (max-width: 768px) {
    width: 80vw;
  } */
`;
export const FileViewWrapper = styled.div<{ mp4?: boolean }>`
  position: absolute;
  display: flex;
  background-color: white;
  top: -90px;
  left: -10vw;
  right: -10vw;
  height: ${(props) => (props?.mp4 ? "40vh" : "100vh")};
  justify-content: center;
  z-index: 2;
`;
