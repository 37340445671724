import styled from "styled-components";
import { MdPrint, MdShare, MdStar, MdVisibility } from "react-icons/md";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { FileShare } from "../FileActions/Share";
import { TreeNodeType } from "../TreeNode";
import { Dispatch, useEffect, useState } from "react";
import { FilePreview } from "../FileActions/Preview";
import { FileDelete } from "../FileActions/Delete";
import usePermissions from "../../hooks/usePermissions";
import { userInfo } from "../../store/userInfo";
import { useRecoilState, useRecoilValue } from "recoil";
import { FileDownload } from "../FileActions/Download";
import { isLoading } from "../sumPdf";
interface IProps {
  // node: { name: string; _id: string };
  node: TreeNodeType;
  setFile: Dispatch<React.SetStateAction<string>>;
  onClickFunc: (question: string, url: string) => void;
}

export const fileDownload = (email: String, filename: string) => {
  return axios
    .get(`${BASE_URL}/tree/downloadFile/${filename}`, {
      withCredentials: true,
    })
    .then((res) => {
      axios.post(
        `${BASE_URL}/fileLogs/sendFileLog`,
        { email, action: "Download", fileName: filename },
        {
          withCredentials: true,
        }
      );
      return res.data;
    })
    .catch((err) => {
      console.log(err);

      return false;
    });
};

export const OptionsBar: React.FC<IProps> = ({
  node,
  setFile,
  onClickFunc,
}: IProps) => {
  const [text, setText] = useState("");
  const [shortName, setShortName] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [fileType, setFileType] = useState(node.name.split(".").slice(-1)[0]);
  const [question, setQuestion] = useState("");
  const [url, setUrl] = useState("");
  const file = node.name;
  useEffect(() => {
    fileDownload(email, file).then((result) => {
      setUrl(result);
    });
  }, []);

  const { isContentManagerUser, isAdminUser } = usePermissions();

  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;

  useEffect(() => {
    const maxChars = 25;
    let trimmedName = node.displayName.split(".").slice(0, -1).join(".");
    if (trimmedName) {
      if (trimmedName.length > maxChars) {
        trimmedName = trimmedName.substring(0, maxChars - 1) + "..";
      }
      setShortName(trimmedName);
      setText(trimmedName);
    }
  }, []);

  useEffect(() => {
    if (text != "קישור לקובץ הועתק בהצלחה." && text != "טוען...") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  }, [text]);

  const [isLoad, setIsLoading] = useRecoilState(isLoading);

  return (
    <>
      {showPopup && <ResultPopup>{text}</ResultPopup>}
      <BarStyle scale={1.5}>
        <AiInput
          placeholder="שאל שאלה על קובץ זה..."
          type="text"
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
          value={question}
        ></AiInput>
        <CalcButton
          onClick={() => {
            onClickFunc(question, url);
            setIsLoading(true);
          }}
        >
          {!isLoad && "חשב תשובה"}
          {isLoad && "טוען תשובה..."}
        </CalcButton>
        <Buttons>
          {fileType == "pdf" && (
            <FilePreview
              setText={setText}
              setFile={setFile}
              filename={node.name}
              email={user.user.userPrincipalName}
              shortName={shortName}
            />
          )}
          {fileType && (
            <FileDownload
              setText={setText}
              filename={node.name}
              email={user.user.userPrincipalName}
              shortName={shortName}
            />
          )}
          {(isContentManagerUser || isAdminUser) && fileType && (
            <FileDelete
              setShowModal={setShowModal}
              setfileName={setText}
              setText={setText}
              node={node}
              shortName={shortName}
            />
          )}
          {fileType && (
            <FileShare
              setText={setText}
              filename={node.name}
              email={email}
              shortName={shortName}
            />
          )}
        </Buttons>
      </BarStyle>
    </>
  );
};

const ResultPopup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -8vh;
  margin-bottom: -6.3vh;
  height: 6vh;
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
`;
const Buttons = styled.div`
  width: auto;
  display: flex;
  justify-content: space-around;
`;
interface BarProps {
  scale: number;
}

const BarStyle = styled.div<BarProps>`
  width: 100%;
  background: #2e3841;
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 1.05rem;
  flex-direction: column;
  /* margin-top: -0.1rem; */
  button {
    background: none;
    border: none;
    margin: 0.7rem auto;
  }
  img {
    margin-top: 0.4rem;
  }
  .star {
    width: calc(5 / 3 * 0.7rem);
    height: 0.7rem;
    transform: ${(props) => `scale(${props.scale})`};
  }
  .eye {
    width: calc(5 / 3 * 0.7rem);
    height: 0.7rem;
    transform: ${(props) => `scale(${props.scale})`};
  }

  .share {
    width: calc(5 / 3 * 0.7rem);
    height: 0.7rem;
    transform: ${(props) => `scale(${props.scale})`};
  }
`;

// const CalcButton = styled.div`
//   background-color: #434040;
//   text-align: center;

//   color: #ffffff;
// `;

const CalcButton = styled.div`
  background-color: #3195ed; 
  color: #ffffff; 
  text-align: center;
  height: 30px;
  width: 100px; 
  font-size: 16px;
  cursor: pointer; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  border-radius: 10px;
  position: relative;
  right: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease; 

  &:hover {
    background-color: #616161; 
  }

  &:active {
    background-color: #353535; 
    transform: scale(1.05); 
  }
`;

const AiInput = styled.input`
  /* width: 80vw; */
  outline: none;
  height: 4vh;
`;
