import styled, { keyframes } from "styled-components";
interface PlusProps {
  buttonPosition: boolean;
}

export const PlusButton = styled.div<PlusProps>`
  width: 2.4rem;
  height: 2.4rem;
  background-color: #3c85dc;
  border-radius: 2rem;
  position: absolute;
  top: 8rem;
  right: calc(50vw - 1.3rem);
  margin: auto;
  // z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CardsColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  align-items: center;
`;
export const NewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;

  /* align-items: center; */
`;
export const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ListColWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  margin-right: 2vw;
  margin-bottom: 3vh;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const BackWrapper = styled.div`
  margin-top: 5vh;
  display: flex;
  justify-content: center;
`;
export const BackButton = styled.div`
  display: flex;
  background-color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 5vh;
  border-radius: 1rem;
  font-size: large;
`;
interface listProps {
  name?: string;
  onClickFunc?: () => void;
  isLastPlace: boolean;
}

export const ListWrap = styled.div<listProps>`
  display: flex;
  background-color: ${(p: listProps) => (p.isLastPlace ? "#506584" : "white")};
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 35vw;
  /* max-width: 90vw; */
  height: 4.5vh;
  border-radius: 2rem;
  margin-right: 2vw;
  margin-left: 2vw;
`;
export const HomePageWrap = styled.div`
  display: flex;
  background-color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 22vw !important;
  height: 4.5vh;
  border-radius: 2rem;
  font-size: 14px;
  font-weight: 600;
  margin-left: 2vw;
`;

export const Spinner = styled.div`
  border: 16px solid pink;
  border-top: 16px deeppink solid;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  animation: spin 2s linear infinite;
  /* 
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite; */

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const spin = keyframes`
  0% {
     transform: rotate(0deg);
     }
  100% {
     transform: rotate(360deg);
      }
`;

export const Spinner2 = styled.div`
  position: relative;
  bottom: 2.4rem;
  right: 1.25rem;
  border: 0.3rem solid #f3f3f3; /* Light grey */
  border-top: 0.3rem solid #8fc8fa; /* Blue */
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  z-index: -1;
  animation: ${spin} 2s linear infinite;
`;

export const PopupWrapper = styled.div`
  margin-top: 10vh;
`;
export const ArrowImg = styled.img`
  min-width: 1.5rem;
`;

export const NoNodesWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;
export const NoNodes = styled.div`
  width: 50vw;
  height: 6vh;
  border-radius: 0.7rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const BigWrap = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
`;
